<!--
File: Report_10_Section_wise_AADT.vue
Description: produce report showing Region/DEU-wise Condition index.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown class='region-select-dropdown' :label="$t('label.select_region')" v-model="selectedRegion"
            :initial_value="selectedRegion" @input='onRegionChange' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <RoadsDropdown :label="$t('label.select_road')" v-model="selectedRoad" :initial_value="selectedRoad"
            :region_id="selectedRegion" :disabled='!selectedRegion' @input='onRoadChange' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <YearsDropdown class='region-select-dropdown' :label="$t('road_network.survey_year')" v-model="selectedYear"
            :initial_value="selectedYear" />
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled"> {{ $t('buttons.print') }} </md-button>
        </div>
      </div>
    </md-card-header>
    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />

      <md-table class="paginated-table table-striped table-hover" :value="report" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell style="max-width: 200px;" :md-label="$t('road_network.section')"
            md-sort-by="section_description">
            {{ item.section_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_a1_spc')" md-sort-by="aadt_a1_spc" md-numeric>
            {{ item.aadt_a1_spc | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_b1_mb')" md-sort-by="aadt_b1_mb" md-numeric>
            {{ item.aadt_b1_mb | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_b2_l2tr')" md-sort-by="aadt_b2_l2tr" md-numeric>
            {{ item.aadt_b2_l2tr | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_b3_tractors')" md-sort-by="aadt_b3_tractors" md-numeric>
            {{ item.aadt_b3_tractors | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_c1_m2tr')" md-sort-by="aadt_c1_m2tr" md-numeric>
            {{ item.aadt_c1_m2tr | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_c2_h3tr')" md-sort-by="aadt_c2_h3tr" md-numeric>
            {{ item.aadt_c2_h3tr | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_d1_buses')" md-sort-by="aadt_d1_buses" md-numeric>
            {{ item.aadt_d1_buses | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_d2_truck_tr')" md-sort-by="aadt_d2_truck_tr" md-numeric>
            {{ item.aadt_d2_truck_tr | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_d3_ar4tr_tr')" md-sort-by="aadt_d3_ar4tr_tr" md-numeric>
            {{ item.aadt_d3_ar4tr_tr | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('traffic.aadt_total')" md-sort-by="aadt_total" md-numeric>
            {{ item.aadt_total | numFormat }}
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>
  </md-card>
</template>
<script>
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import RoadsDropdown from '../Dropdowns/RoadsDropdown.vue'
  import YearsDropdown from '../Dropdowns/YearsDropdown.vue'
  import { numFormat } from "@/store/refdata/format_helpers"
  import { customSortMixin } from '@/customSortMixin'

  export default {
    mixins: [customSortMixin],
    name: 'report-section_wise_aadt',
    data() {
      return {
        selectedRegion: null,
        selectedRoad: null,
        selectedYear: new Date().getFullYear(),
        btnDisabled: true,

        regionDescr: null,
        roadDescr: null,
        showSpinner: false,
        currentSort: 'section_description',
        currentSortOrder: 'asc',
      }
    },
    components: {
      RegionsDropdown,
      RoadsDropdown,
      YearsDropdown,
    },

    mounted() {
      this.$store.state.Reports.report_10_data = []
      //this.reloadData()
    },

    methods: {
      reloadData() {
        //if (!this.selectedYear) { return }
        if (!this.selectedRegion) { return }

        this.showSpinner = true
        this.btnDisabled = true
        const reportParams = {
          region_id: this.selectedRegion,
          road_id: this.selectedRoad,
          selectedYear: this.selectedYear
        }
        this.$store.dispatch('REPORT_10_SECTION_WISE_AADT', reportParams).then(() => {
          this.btnDisabled = false
          this.showSpinner = false
        })
      },
      print() {
        this.showSpinner = true
        let prtHtml = "<h4 style='text-align:center'>" + this.$t('label.reports_title') + "</h4>"
        prtHtml += "<h4 style='text-align:center'>" + this.$t('label.report10_title')
        prtHtml += '&nbsp;' + this.$t('label.as_on') + this.selectedYear + '<br>'

        let titleFilters = ''
        let tableHeaders = '<tr>'
        if (this.selectedRegion) {
          titleFilters += this.$t('label.from_region', { name: this.regionDescr })
        }
        if (this.selectedRoad) {
          titleFilters += ', &nbsp;' + this.$t('road_network.road') + ': ' + this.roadDescr
        }
        if (titleFilters !== '') prtHtml += '&nbsp;(' + titleFilters + ')'
        prtHtml += '</h4>'

        tableHeaders += `<th>${this.$t('road_network.section')}</th>
          <th>${this.$t('traffic.aadt_a1_spc')}</th>
          <th>${this.$t('traffic.aadt_b1_mb')}</th>
          <th>${this.$t('traffic.aadt_b2_l2tr')}</th>
          <th>${this.$t('traffic.aadt_b3_tractors')}</th>
          <th>${this.$t('traffic.aadt_c1_m2tr')}</th>
          <th>${this.$t('traffic.aadt_c2_h3tr')}</th>
          <th>${this.$t('traffic.aadt_d1_buses')}</th>
          <th>${this.$t('traffic.aadt_d2_truck_tr')}</th>
          <th>${this.$t('traffic.aadt_d3_ar4tr_tr')}</th>
          <th>${this.$t('traffic.aadt_total')}</th>
        </tr>`

        let tableRows = ''
        this.report.forEach(item => {
          tableRows += `<tr><td>${item.section_description}</td>
            <td class='numCell'>${numFormat(item.aadt_a1_spc)}</td>
            <td class='numCell'>${numFormat(item.aadt_b1_mb)}</td>
            <td class='numCell'>${numFormat(item.aadt_b2_l2tr)}</td>
            <td class='numCell'>${numFormat(item.aadt_b3_tractors)}</td>
            <td class='numCell'>${numFormat(item.aadt_c1_m2tr)}</td>
            <td class='numCell'>${numFormat(item.aadt_c2_h3tr)}</td>
            <td class='numCell'>${numFormat(item.aadt_d1_buses)}</td>
            <td class='numCell'>${numFormat(item.aadt_d2_truck_tr)}</td>
            <td class='numCell'>${numFormat(item.aadt_d3_ar4tr_tr)}</td>
            <td class='numCell'>${numFormat(item.aadt_total)}</td>
          </tr>`
        })

        const PrintWin = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        PrintWin.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <style>
              body { font-family: Arial, sans-serif; }
              table { width: 100%; border-collapse: collapse; margin: 20px 0; font-size: 11px; }
              th, td { border: 1px solid black; padding: 5px; overflow: visible; white-space: normal; word-wrap: break-word; }
              .numCell { text-align: right; padding-right: 5px; }
            </style>
          </head>
          <body>${prtHtml}
            <table>
              <thead>${tableHeaders}</thead>
              <tbody>${tableRows}</tbody>
            </table>
          </body>
        </html>`
        )

        PrintWin.document.close();
        this.showSpinner = false
        PrintWin.focus();
        PrintWin.print();
        PrintWin.close();
      },
      onRegionChange(region_id, regionDescr) {
        this.regionDescr = regionDescr
        this.reloadData()
      },
      onRoadChange(road_id, roadDescr) {
        this.roadDescr = roadDescr
        this.reloadData()
      },
    },

    computed: {
      report() {
        return this.customSort(this.$store.state.Reports.report_10_data)
        //return this.customSort(this.$store.state.Reports.report_10_data, 'section_description')
      },
    },

    watch: {
      selectedYear(newValue) {
        this.reloadData()
      },
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  font-size: 13px !important;
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  font-size: 12px !important;
  height: 36px;
  padding: 0px !important;
  padding-right: 24px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>